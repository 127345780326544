function NavBar() {
  return (
    <nav className="nav">
      <img
        className="logo"
        src="https://res.cloudinary.com/branchesventures/image/upload/v1624205563/website/branches_ventures_logo_cth0k2.png"
      />
    </nav>
  );
}

export default NavBar;
