import "./App.css";
import NavBar from "./NavBar.js";

function App() {
  return (
    <div>
      <NavBar />
      <section className="hero-section">
        <div className="hero-value-content">
          <h1>Investing in the Future</h1>
          <p className="value-prop">
            We believe in working with our partners to connect people to
            dwellings they love through real estate development
          </p>
        </div>
        <img
          className="hero-image"
          src="https://images.unsplash.com/photo-1523217582562-09d0def993a6?ixid=Mnw3MjU4M3wwfDF8c2VhcmNofDMwfHxob21lfGVufDB8fHx8MTYyNDIwMDg5OA&ixlib=rb-1.2.1&w=2448&q=70&auto=format&fit=crop&h=1152"
        />
      </section>
      <section className="our-mission-section">
        <div className="value-content our-mission-content">
          <h2>Our Mission</h2>
          <p className="value-prop">
            Home is where the heart is. Our Mission is to provide quality
            dwellings for our tenants.
          </p>
        </div>
        <img
          className="value-image"
          src="https://images.unsplash.com/photo-1567767292278-a4f21aa2d36e?ixid=Mnw3MjU4M3wwfDF8c2VhcmNofDEwMHx8aG9tZXxlbnwwfHx8fDE2MjQyMDA5MDY&ixlib=rb-1.2.1&w=984&q=70&auto=format&fit=crop&h=524"
        />
      </section>
      <section className="people-section">
        <div className="value-content people-value-content">
          <h2>People</h2>
          <p className="value">
            We value relationships and individuals. We take pride in working
            closely with our partners and tenants with respect and integrity.
          </p>
        </div>
        <img
          className="value-image people-section-image"
          src="https://images.unsplash.com/photo-1567225557594-88d73e55f2cb?ixid=Mnw3MjU4M3wwfDF8c2VhcmNofDgxfHxob21lfGVufDB8fHx8MTYyNDIwMTQ0Mw&ixlib=rb-1.2.1&w=2104&q=70&auto=format&fit=crop&h=1120"
        />
      </section>
      <footer>
        <a href="mailto:whitney@branchesventures.com">Contact Us</a>
      </footer>
    </div>
  );
}

export default App;
